@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.service {
  @include desktop-md {
    margin-bottom: rem(11);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(25);
  }

  position: relative;
  overflow: hidden;
  width: rem(240);
  border: rem(1) solid $brown;
  border-radius: rem(4);
  margin-left: rem(13);
}

.service:nth-child(n + 3) {
  @include desktop-md {
    margin-right: 0;
  }
}

.service:nth-child(n + 5) {
  @include desktop-md {
    margin-right: 0;
  }
}

.service:nth-child(n + 4) {
  @include desktop-md {
    display: none;
  }
}

.service:last-child {
  @include desktop-l-and-bigger {
    margin-right: 0;
  }
}

.serviceHeader {
  position: relative;
  overflow: hidden;
  margin: 0;
}

.headerLegend {
  position: absolute;
  bottom: rem(17);
  left: rem(24);
}

.headerTitle {
  margin-bottom: 0;
  color: $light;
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(17);
}

.serviceImg {
  height: rem(160);
}

.serviceImageDarker {
  filter: brightness(50%);
}

.serviceBody {
  padding: rem(24);
}

.costWrapper {
  min-height: rem(53);
  margin-bottom: rem(8);
}

.bodyDesc {
  overflow: hidden;
  height: rem(100);
  color: $brown;
  line-height: rem(17);
}

.bodyBtn {
  width: 100%;
  text-transform: uppercase;
  background-color: $light;
}

.moreInfoLink {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: rem(17) rem(24) rem(14);
  border: rem(1) solid $brown;
  border-radius: rem(4);
  margin-bottom: rem(5);
  color: $brown;
  font-weight: bold;
  text-transform: uppercase;
}

.costPrefix {
  color: $brown;
  line-height: rem(17);
}

.serviceCost {
  color: $red;
  font-weight: 700;
  font-size: rem(24);

  sup {
    top: rem(-5);
  }
}
