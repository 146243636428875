@import 'abstracts/functions';
@import 'abstracts/variables';

.closeModal {
  float: right;
  cursor: pointer;
}

.modalContent {
  padding: rem(30);
  background-color: $light;
}
