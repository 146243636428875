@import 'abstracts/variables';
@import 'abstracts/functions';

.container {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: rem(1) solid $gray-light-3;
  border-radius: 0.25rem;
  margin: 0.75rem 0;
  background: $gray-light-4;
}

.danger {
  border-color: $red-2;
  background: $red-3;
}

.success {
  border-color: $green-light-1;
  color: $green-dark;
  background: $green-light;
}
