@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.network {
  position: relative;
  max-width: rem(600);
  margin: auto;
  color: $brown;
  text-align: justify;
}

.networkSection {
  padding: rem(70) 0 rem(70) 0;
  background: $gray-light-3;
}

.networkCursor {
  cursor: pointer;
}

.networkTitle {
  @include mobile {
    font-size: rem(16);
    line-height: rem(19);
  }

  @include desktop {
    font-size: rem(20);
    line-height: rem(24);
  }

  margin-bottom: rem(24);
  color: $brown;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.networkDescription {
  position: relative;
  overflow: hidden;
  height: auto;
}

.networkDescriptionUnfolded {
  height: rem(215);
}

.networkReadMore {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  margin: 0 !important;
  margin-bottom: 0;
  /* stylelint-disable color-function-notation */
  background-image: linear-gradient(
    to bottom,
    rgba($light, 40%),
    $gray-light-3
  );
  /* stylelint-enable color-function-notation */
}

.networkBtnReadMore {
  display: flex;
  align-items: center;
  border: 0;
  color: $brown;
  font-weight: 700;
  background-color: transparent;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    border: 0;
    color: $brown !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}

.networkBtnReadMore:hover {
  color: $brown;
}
