@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.newsletter {
  @include mobile {
    padding: rem(48) rem(48) rem(56);
  }

  @include desktop {
    padding: rem(72) 0;
  }

  text-align: center;
  background-color: $red;
}

.newsletterForSideBar {
  @include mobile {
    padding: rem(24) rem(37) !important;
    border-radius: 0;
    margin-top: 0;
  }

  padding: rem(24) rem(9) !important;
  border-radius: rem(6);
  margin-top: rem(24);

  .newsletterTitle {
    @include desktop {
      text-align: left;
    }

    font-size: rem(14);
  }

  .newsletterInlineFormCol {
    flex: none;
    width: 89%;
    max-width: 100% !important;
    padding: 0 !important;
    margin-bottom: rem(11);
  }

  .newsletterInlineFormBtn {
    width: 89%;
  }
}

.newsletterTitle {
  @include mobile {
    margin-bottom: rem(16);
  }

  @include desktop {
    margin-bottom: rem(5);
  }

  margin: 0;
  color: $light;
  font-size: rem(20);
  line-height: rem(24);
}

.newsletterDescription {
  color: $light;
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(19);
}

.newsletterInlineForm {
  justify-content: center;
}

.newsletterInlineFormCol {
  @include mobile {
    flex: none;
    width: 100%;
    max-width: 100% !important;
    padding: 0 !important;
  }
}

.newsletterInlineFormGroup {
  margin-bottom: 0;
}

.newsletterInlineFormInput {
  @include mobile {
    margin-bottom: rem(14) !important;
  }

  height: rem(51);
  padding: rem(23) rem(26);
  color: $brown;
  font-style: italic;
}

.newsletterInlineFormInput::placeholder {
  color: $brown;
}

.newsletterInlineFormBtn {
  @include mobile {
    width: 100%;
    padding: rem(12) rem(22);
  }

  @include desktop {
    max-height: rem(51);
    padding: rem(14) rem(48);
  }

  float: left;
  background: $gray-light-12;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $light;
  }
}

.newsletterInlineFormValidationError {
  color: $light;
  font-size: rem(12);
}
