@import 'abstracts/variables';
@import 'abstracts/functions';

.newsletterModal > div {
  background-color: $light;
}

.newsletterModalBtn {
  display: block;
  width: 100%;
  margin-top: rem(20);
  font-size: rem(18);
}

.newsletterModalHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.newsletterModalBody {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.newsletterModalTitle {
  width: 100%;
  text-align: center;
}

.close {
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: $dark-1;
  text-shadow: 0 rem(1) 0 $light;
  background-color: transparent;
  opacity: 0.5;
}

.newsletterModalFormRequired::after {
  content: '*';
  color: $red;
}

.newsletterInlineFormValidationError {
  color: $light;
  font-size: rem(12);
}

@media (max-width: 430px) {
  .newsletterInlineFormInput {
    margin-bottom: rem(14);
  }
}
