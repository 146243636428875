@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.tooltip {
  position: absolute;
  z-index: 99;
  padding: rem(6) rem(12);
  border-radius: rem(4);
  color: $light;
  font-size: rem(12) !important;
  font-family: $lato;
  white-space: nowrap;
  background-color: $gray-dark-4;
  pointer-events: none;
}

.tooltipLight {
  color: $dark;
  background-color: $light;
  box-shadow: 0 0 rem(13) $gray-dark-4;

  .triangle-top {
    border-color: $light transparent transparent transparent;
  }

  .triangle-right {
    border-color: transparent $light transparent transparent;
  }

  .triangle-bottom {
    border-color: transparent transparent $light transparent;
  }

  .triangle-left {
    border-color: transparent transparent transparent $light;
  }
}

.triangle {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  border-width: rem(6);
  border-style: solid;
}

.triangle-top {
  bottom: rem(-12);
  left: rem(10);
  border-color: $gray-dark-4 transparent transparent transparent;
}

.triangle-right {
  top: 50%;
  left: rem(-11);
  border-color: transparent $gray-dark-4 transparent transparent;
  transform: translateY(-50%);
}

.triangle-bottom {
  top: rem(-12);
  left: rem(10);
  border-color: transparent transparent $gray-dark-4 transparent;
}

.triangle-left {
  top: 50%;
  right: rem(-12);
  border-color: transparent transparent transparent $gray-dark-4;
  transform: translateY(-50%);
}
