@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.news {
  @include desktop-l-and-smaller {
    padding: rem(30) 0;
  }

  width: 100%;
  padding: rem(60);
  background-color: $gray-light-2;
}

.newsWrapper {
  display: flex;
}

.newsTitle {
  @include mobile {
    font-size: rem(16);
    line-height: rem(22);
  }

  @include desktop {
    font-size: rem(20);
    line-height: rem(24);
  }

  color: $brown;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.newsDescription,
.newsDescriptionMobile {
  width: 80%;
  margin: rem(30) auto;
  color: $brown-light;
  text-align: center;
}

.newsDescriptionMobile {
  display: none;
}

.newsSlider {
  @include desktop {
    width: rem(250);
  }

  @include desktop-l {
    width: unset;
  }

  @include desktop-l-and-smaller {
    display: block !important;
    max-height: 100%;
    padding: rem(10);
  }

  @include desktop-xl {
    width: 100%;
  }

  display: flex !important;
  padding: rem(30);
  border-radius: rem(5);
  margin: 0 auto;
}

.newsImageContainer {
  @include desktop-l-and-smaller {
    width: 100%;
    height: rem(180);
  }

  position: relative;
  min-width: rem(227);
  height: rem(180);
  padding: 0;
}

.newsSliderContent {
  @include desktop-l-and-smaller {
    width: 100%;
    height: calc(100% - #{rem(180)});
    padding-bottom: rem(70);
  }

  position: relative;
  width: calc(100% - #{rem(-227)});
  max-width: rem(320);
  height: rem(180);
  padding: rem(20) rem(20) rem(30);
  background: $light;
}

.newsSliderContentTitle {
  @include desktop-l-and-smaller {
    overflow: visible;
    height: rem(45);
    text-align: center;
    white-space: normal;
    margin-block-end: rem(10);
  }

  overflow: hidden;
  font-size: rem(18);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.newsSliderDescription {
  color: $brown;
}

.newsSliderButton {
  @include desktop-l-and-smaller {
    bottom: rem(20);
    left: 15%;
    display: inline-block;
    width: 70%;
  }

  position: absolute;
  right: rem(20);
  bottom: rem(-20);
  overflow: hidden;
  padding: rem(17) rem(24) 1rem;
  border: rem(1) solid $red;
  border-radius: rem(5);
  color: $red;
  font-weight: 700;
  line-height: rem(17);
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: $light;
}

.newsSliderArrowNext {
  @include desktop-l-and-smaller {
    right: rem(-30);
  }

  right: rem(-20);
  font-size: rem(50);
  background-image: none;
}

.newsSliderArrowPrev {
  left: rem(-50);
  font-size: rem(50);
  background-image: none;
}

.newsSliderDescriptionMobile {
  display: none;
  color: $brown;
}

.newsDescriptionMobile,
.newsSliderDescriptionMobile {
  @include desktop-l-and-smaller {
    display: block;
  }
}

.newsDescription,
.newsSliderDescription {
  @include desktop-l-and-smaller {
    display: none;
    width: 100%;
  }
}

.nextArrow {
  right: rem(-15) !important;
}

.prevArrow {
  left: rem(-15) !important;
}
