@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.engagementsSection {
  @include mobile {
    padding: rem(72) 0;
  }

  justify-content: center;
  padding: rem(72) rem(10);
}

.engagements {
  max-width: rem(960);
  height: rem(216);
  padding: 0 !important;
}

.engagementsTitle {
  margin-bottom: rem(20);

  h2,
  p {
    font-size: rem(20);
    font-weight: 700;
    line-height: rem(24);
  }
}

.engagementsContainer {
  display: flex;
}

.engagementItem,
.engagementItemFull {
  display: block;
  width: rem(240);
  padding: rem(20) rem(10);
  color: $brown;
  text-align: center;
  text-decoration: none;
}

.engagementItemFull:hover {
  @include mobile {
    margin-top: rem(-25);
    box-shadow: none !important;
  }

  justify-content: center;
  height: auto;
  min-height: rem(150);
  cursor: pointer;
}

.engagementItemFull:hover .engagementItemIcons {
  @include mobile {
    margin-top: 0;
  }

  margin-top: rem(-10);
  transform: scale(0.85);
  transition: margin 100ms;
}

.engagementItemFull:hover .engagementItemDescription {
  @include mobile {
    margin-top: 0;
  }
}

.engagementItemTitle {
  max-width: rem(180);
  margin: auto;
  color: $brown;
  font-weight: 400;
  font-size: rem(16);
}

.engagementItemDescription {
  margin-top: rem(10);
  color: $brown;
}

.engagementItemIcons {
  display: flex;
  justify-content: center;
  margin: 0 auto rem(14);
  color: $red;
  font-size: rem(50);
}

.engagementItemFull:hover section {
  padding-bottom: 0;
}

.edges {
  opacity: 0.2;
}

.minimized {
  padding: 0;

  .engagementsTitle {
    margin-bottom: rem(10);
    font-size: rem(16);
    text-align: center;

    h2,
    p {
      font-size: rem(16);
    }
  }

  .engagements {
    max-width: 100%;
    height: auto;
    padding: 0;
  }

  .engagementItem {
    padding: rem(10) rem(5);
  }

  .engagementItemTitle {
    font-size: rem(14);
    font-weight: 200;
  }
}
