@import 'abstracts/variables';
@import 'abstracts/functions';

/**
 ** 320                 768                  1024                 1366                 1920                  ...
 ** |      MOBILE_XS     |      MOBILE_S      |     DESKTOP_MD      |     DESKTOP_L      |     DESKTOP_XL     |
 ** |                 MOBILE                  |                             DESKTOP
 */

// #{if(&, "&", "*")} => https://github.com/sass/sass/issues/1873#issuecomment-152293725
// :not(:root) allows our media queries to have greater specificity than the default rules

// Responsive

// Specific ranges

@mixin mobile-xs {
  @media (max-width: #{$mq-mobile-s - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin mobile-s {
  @media (min-width: #{$mq-mobile-s}) and (max-width: #{$mq-desktop-md - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-md {
  @media (min-width: #{$mq-desktop-md}) and (max-width: #{$mq-desktop-l - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-l {
  @media (min-width: #{$mq-desktop-l}) and (max-width: #{$mq-desktop-xl - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-xl {
  @media (min-width: #{$mq-desktop-xl}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

// !Specific ranges

// Large ranges

// Same as "mobile"
@mixin mobile-s-and-smaller {
  @media (max-width: #{$mq-mobile-s - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin mobile-s-and-bigger {
  @media (min-width: #{$mq-mobile-s}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-md-and-smaller {
  @media (max-width: #{$mq-desktop-md- 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

// Same as "desktop"
@mixin desktop-md-and-bigger {
  @media (min-width: #{$mq-desktop-md}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-l-and-smaller {
  @media (max-width: #{$mq-desktop-l - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop-l-and-bigger {
  @media (min-width: #{$mq-desktop-l}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

// !Large ranges

// Generic groups

@mixin mobile {
  @media (max-width: #{$mq-desktop-md - 0.5px}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

@mixin desktop {
  @media (min-width: #{$mq-desktop-md}) {
    #{if(&, "&", "*")}:not(:root) {
      @content;
    }
  }
}

// !Generic groups

// !Responsive

@mixin HEXtoRGBA($property, $color, $opacity: 1) {
  // stylelint-disable-next-line color-function-notation
  #{$property}: rgba($color, $opacity);
}

@mixin flex-grid-items($cols, $gap) {
  $flex-gap: rem($gap);

  display: flex;
  flex-wrap: wrap;
  gap: $flex-gap;

  > * {
    $flex-width: calc(100% / $cols);

    box-sizing: border-box;
    width: calc(#{$flex-width} - #{rem($gap - 5)});
  }
}

@mixin line-clamp($lines) {
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  -webkit-line-clamp: $lines;
}

@mixin col($size, $columns: 12) {
  flex: 0 0 #{calc($size * 8.3333) + '%'};
  max-width: #{calc($size * 8.3333) + '%'} !important;
}

@mixin triangle($size: 2rem, $background: $red, $radius: true) {
  position: relative;
  overflow: hidden;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
  pointer-events: none;

  &,
  &::before,
  &::after {
    width: $size;
    height: $size;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    background: $background;
    pointer-events: auto;
  }

  &::before {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%)
      skewX(30deg) scaleY(0.866) translateX(-24%);
  }

  &::after {
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%)
      skewX(-30deg) scaleY(0.866) translateX(24%);
  }

  @if ($radius) {
    border-radius: 20%;

    &::before {
      border-radius: 20% 20% 20% 55%;
    }

    &::after {
      border-radius: 20% 20% 55%;
    }
  }
}
