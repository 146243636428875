@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/variables';

.partners {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: rem(72) 0;
  margin: auto;
}

.partnersTitle {
  @include mobile {
    font-size: rem(16);
    line-height: rem(19);
  }

  margin: 0 0 rem(25);
  color: $brown;
  font-weight: 700;
  font-size: rem(20);
  line-height: rem(24);
  text-align: center;
  text-transform: uppercase;
}

.partnersSlide {
  width: rem(1128);
}

.partnerItem {
  display: block;
  width: rem(100);
  height: rem(50);
}

.partnerItemBrand {
  width: 100%;
  height: 100%;
  padding: 0 rem(10);
  object-fit: contain;
}
