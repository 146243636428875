@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.textFunding {
  display: inline;
  float: left;
  margin-right: rem(8);
  color: $gray-dark;
  font-size: rem(16);
  text-transform: uppercase;
}

.fundingText {
  color: $red;
  font-weight: 100;
  font-size: 0.7em;
  text-transform: uppercase;

  strong {
    font-weight: 500;
    font-size: 1.7em;
  }

  p {
    margin-bottom: -5px;
  }
}

.fundingTextIcon {
  z-index: 100;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  fill: $gray-dark;
}
