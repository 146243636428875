@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.services {
  @include mobile {
    padding: rem(33) 0;
  }

  @include desktop {
    padding: rem(72) rem(72) rem(30) rem(72);
  }

  @include desktop-md {
    padding-bottom: rem(44);
  }

  background-color: $gray-light;
}

.arrowLeft {
  left: rem(13);
}

.arrowRight {
  right: rem(13);
}

.servicesWrapper {
  @include desktop-md {
    max-width: rem(770);
    margin: 0 auto;
  }

  @include desktop-l-and-bigger {
    max-width: rem(1300);
    margin: 0 auto;
  }
}

.servicesDescription,
.servicesDescriptionMobile {
  color: $brown-light;
  line-height: rem(17);
  text-align: center;
}

.servicesDescription {
  @include mobile {
    display: none;
  }

  @include desktop-l-and-bigger {
    max-width: rem(768);
    margin-right: auto;
    margin-left: auto;
  }
}

.servicesDescriptionMobile {
  @include desktop {
    display: none;
  }
}

.servicesTitle {
  @include mobile {
    margin-bottom: rem(20);
    font-size: rem(16);
  }

  @include desktop {
    margin-bottom: rem(23);
    font-size: rem(20);
  }

  color: $brown;
}

.servicesTitleTag {
  font-size: inherit;
  font-weight: inherit;
}

.servicesHeader {
  @include mobile {
    max-width: 80%;
    margin-bottom: rem(13);
  }

  @include desktop {
    margin-bottom: rem(21);
  }

  margin: 0 auto;
}

.servicesLink.servicesLink {
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.linkWrapper {
  @include mobile {
    text-align: center;
  }

  @include desktop {
    text-align: right;
  }

  padding-top: rem(20);
}

.linkTxt {
  color: $brown;
  text-decoration: underline;
}

.linkIcon {
  display: inline-block;
  font-weight: 700;
  vertical-align: middle;
  fill: $red;
}

.servicesList {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
}

.servicesItem {
  @include desktop {
    width: rem(234);
  }

  @include desktop-md {
    margin-bottom: rem(11);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(25);
  }

  position: relative;
  overflow: hidden;
  display: inline-block;
  max-width: rem(240);
  height: rem(432);
  border: rem(1) solid $brown;
  border-radius: rem(4);
  margin: 0 rem(3);
}

.servicesItem:nth-child(n + 3) {
  @include desktop-md {
    margin-right: 0;
  }
}

.servicesItem:nth-child(n + 5) {
  @include desktop-md {
    margin-right: 0;
  }
}

.servicesItem:nth-child(n + 4) {
  @include desktop-md {
    display: none;
  }
}

.servicesItem:last-child {
  @include desktop-l-and-bigger {
    margin-right: 0;
  }
}
