@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0 0 0 / 40%);
  transition: opacity 0.3s ease;

  &.open {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
}

.dialog {
  position: relative;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  border-radius: rem(5);
  background-color: transparent;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}

.body {
  overflow: visible;
}
