@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.overlayContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.overlay {
  position: absolute;
  padding: rem(6) rem(12);
}

.overlay-top {
  top: calc(-100% - rem(5));
  left: -150%;
}

.overlay-right {
  top: 0;
  left: calc(100% + rem(5));
}

.overlay-bottom {
  top: calc(100% + rem(5));
  left: 0;
}

.overlay-left {
  top: 0;
  left: calc(-100% - rem(5));
}
