@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.vehicleVignette {
  width: 100%;
  height: 100%;
  border-radius: rem(4);
}

.imgContainer {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.smallImgContainer {
  width: 100%;
}

.imgVehicle > div {
  position: relative !important;

  &::before {
    content: '';
    display: block;
    padding-top: 61.442%;
  }
}

.imgVehicle img {
  object-fit: cover;
  object-position: center;
}

.tag {
  position: absolute;
  top: rem(12);
  left: 0;
  padding: rem(4);
  color: $light;
  font-weight: 900;
  font-size: rem(10);
  line-height: rem(12);
  text-transform: uppercase;
  background: $red;
}

.tagDark {
  background-color: $brown;
}

.nbImgs {
  position: absolute;
  bottom: rem(10);
  left: rem(10);
  display: flex;
  align-items: center;
  padding: rem(5);
  border-radius: rem(3);
  color: $light;
  font-size: rem(12);
  line-height: rem(14);
  background: rgb(255 255 255 / 50%);
}

.nbImgsIcon {
  width: rem(14);
  height: rem(14);
  margin-right: rem(5);
  fill: currentcolor;
}

.selectionToggler {
  @include desktop {
    opacity: 0;
  }

  position: absolute;
  right: rem(10);
  bottom: rem(10);
  z-index: 2;
  width: rem(20);
  height: rem(20);
  padding: 0;
  border: 0;
  border-radius: 50%;
  background: none;
  background: $gray-light;
}

.selectionToggler,
.selected .sSelectionToggler:focus,
.selected .selectionToggler:hover {
  color: $brown;
}

.selectionToggler:focus,
.selectionToggler:hover,
.selected .selectionToggler {
  color: $red;
}

.selectionIcon {
  position: absolute;
  width: rem(12);
  height: rem(12);
  margin: auto;
  fill: currentcolor;
  inset: 0;
}

.selectionIconFull,
.selected .selectionToggler:focus .selectionIcon--full,
.selected .selectionToggler:hover .selectionIconFull {
  opacity: 0;
}

.selectionToggler:focus .selectionIconFull,
.selectionToggler:hover .selectionIconFull,
.selected .selectionIconFull {
  opacity: 1;
}

.content {
  @include desktop {
    padding: rem(10);
  }

  flex: auto;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: rem(20);
}

.title {
  margin-bottom: 0;
  text-align: left;

  h4 {
    font-weight: 700;
  }
}

.version {
  display: block;
  font-weight: normal;
  font-size: rem(14);
  line-height: rem(17);
  text-transform: none;
}

.link,
.link:focus,
.link:hover {
  color: inherit;
}

.link {
  position: initial;
  z-index: 1;
  display: block;
  display: flex;
  flex: auto;
  flex-flow: column;
  height: 100%;
  border: rem(2) solid $brown-2;
  border-radius: rem(4);

  &.row {
    flex-direction: row;
  }
}

.link:focus,
.link:hover,
.link:hover .link {
  border-width: rem(2);
  border-color: $red;
}

.link:focus-within .link::before {
  border-width: rem(2);
  border-color: $red;
}

.infoList {
  margin-top: rem(10);
  font-size: rem(12);
  line-height: rem(14);

  ul {
    padding-left: 0;
    list-style: none;
  }
}

.infoList > ul {
  display: flex;
  flex-wrap: wrap;
}

.infoList > ul > li:not(:last-child)::after {
  content: '';
  display: inline-block;
  width: rem(6);
  height: rem(6);
  border-radius: 50%;
  margin-right: rem(5);
  margin-left: rem(5);
  vertical-align: middle;
  background: $red;
}

.equipmentsList {
  margin-top: rem(10);

  > ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: rem(7) !important;
    padding-right: rem(7) !important;
    margin-right: rem(-15);
    margin-left: rem(-15);
    list-style: none;

    li {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding-left: rem(7) !important;
      padding-right: rem(7) !important;
    }
  }
}

.equipment {
  position: relative;
  display: block;
  width: rem(28);
  height: rem(28);
  border-radius: 50%;
  color: $red;
  background: $gray-light;
}

.equipment svg {
  position: absolute;
  width: rem(18);
  height: rem(18);
  margin: auto;
  fill: currentcolor;
  inset: 0;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: rem(20);
  margin-top: auto;
}

.price {
  display: block;
  margin-bottom: rem(5);
  color: $brown;
  font-weight: bold;
  font-size: rem(18);
  line-height: rem(22);
  letter-spacing: 0.02em;
}

.priceOriginal {
  display: block;
  font-size: rem(14);
  line-height: rem(17);
  text-decoration: line-through;
  color: $dark-1;
}

.priceSeparator {
  padding-top: rem(4);
  padding-bottom: rem(4);
  margin-right: rem(10);
  margin-left: rem(10);
  color: $gray-medium;
  font-size: rem(12);
  line-height: rem(14);
  text-transform: uppercase;
}

.credit {
  display: flex;
  align-items: baseline;
  color: $red;
}

.creditPrice {
  font-size: rem(14);
  line-height: rem(17);
  font-weight: 500;
}

.creditUnit {
  margin-left: rem(5);
  font-size: rem(10);
  line-height: rem(12);
}

.creditInfoToggler {
  position: relative;
  z-index: 2;
  align-self: center;
  width: rem(14);
  height: rem(14);
  padding: 0;
  border: 0;
  margin-left: rem(5);
  color: $gray-medium;
  background: none;
}

.creditInfoToggler:focus,
.creditInfoToggler:hover {
  color: $brown;
}

.creditInfoToggler svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: currentcolor;
}

.location {
  @include desktop {
    padding-right: rem(10);
    padding-left: rem(10);
    margin: rem(0) rem(-10) rem(-10);
  }

  display: flex;
  align-items: flex-start;
  padding: rem(10) rem(20);
  padding: 0.7142rem 1.4285rem;
  margin: rem(20) rem(-20) rem(-20);
  margin: 0 -1.4285rem -1.4285rem;
  color: $brown;
  font-size: rem(12);
  line-height: rem(17);
  text-transform: uppercase;
  background: $gray-light;
}

.location > svg {
  flex: 0 0 auto;
  width: rem(12);
  height: rem(14);
  margin-right: rem(5);
  fill: $red;
}

.svgIcon {
  flex: 0 0 auto;
  width: rem(12);
  height: rem(14);
  margin-right: rem(7);
  fill: $red;
}

.badgeExclu {
  position: absolute;
  top: rem(10);
  left: 0;
  z-index: 1;
  padding: rem(5);
  color: $light;
  font-weight: 600;
  font-size: rem(10);
  text-transform: uppercase;
  background-color: $red;
}

.badgeText {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: rem(5);
  color: $light;
  font-size: rem(10);
  text-transform: uppercase;
  background-color: $brown;
}

.badgeItemContainer {
  display: flex;
  min-height: rem(50);
  padding: rem(10) 0;
}

.badgeItemHasContent {
  border-top: 1px solid $gray-medium-3;
}

.badgeItemText {
  margin-bottom: 0;
}

.badgeItem {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  align-items: flex-start;
  align-items: center;
  height: max-content;
  padding: 0 rem(10);
  border-right: 1px solid $gray-medium-3;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border: 0;
  }
}

.badgeItem p {
  margin-left: 10px;
  color: $brown;
  font-size: rem(11);
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
  opacity: 1;
}

@media (min-width: 64em) {
  .selectionToggler {
    opacity: 0;
  }

  .vehicleVignette:hover .selectionToggler,
  .selectionToggler:focus {
    opacity: 1;
  }
}

.vehicleVignette:focus-within .selectionToggler {
  @include desktop {
    opacity: 1;
  }
}

.slideshowSection {
  @include desktop {
    padding-top: rem(54);
    padding-bottom: rem(74);

    .content {
      padding: 0.7142rem;
    }

    .location {
      padding-right: 0.7142rem;
      padding-left: 0.7142rem;
      margin: 0 -0.7142rem -0.7142rem;
    }
  }

  padding-top: 3.8571rem;
  padding-bottom: 5.2857rem;
}

.vehicleFichePriceOriginal {
  color: $brown;
  text-decoration: line-through;
}

.badgeItemIcon,
.badgeItemText {
  vertical-align: middle;
}

.isSmall {
  .link {
    flex-direction: row;
    border: 0;
    background-color: $light;
  }

  .content {
    flex-shrink: 1.5;
  }

  .imgVehicle,
  .imgVehicle > div {
    height: 100%;
  }
}

.ttc {
  margin-left: rem(5);
  font-weight: 500;
  font-size: rem(12);
}

.priceLabel {
  font-size: rem(12);
  font-weight: 500;
}

.fundingVehicle {
  border-left: 1px solid $gray-medium;
}

.vehicleOverlayTrigger {
  > div {
    display: flex;
    align-items: center;
    height: rem(20);
  }
}

.vehicleTooltip {
  width: rem(150);
  white-space: wrap;
}
