@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.promise {
  @include mobile {
    padding: rem(18) 0 0 rem(20);
    background-position-x: 0;
    background-size: 15%;
  }

  @include desktop-md {
    padding: rem(43) 0 0 rem(20);
    background-position-x: 0;
    background-size: 15%;
  }

  @include desktop-l {
    padding: rem(108) 0 0 0;
  }

  display: flex;
  flex-direction: column;
  padding: rem(150) 0 0 0;
}

.promiseGarage {
  @include mobile {
    justify-content: center;
  }

  @include desktop {
    margin-top: rem(25);
  }
}

.promiseGarage .promise {
  @include desktop {
    justify-content: center;
    max-width: rem(196);
    padding-top: 0;
    padding-left: rem(10);
  }
}

.promiseImage {
  @include mobile {
    height: rem(70);
    width: rem(60);
    margin: 0 0 0 5%;
  }

  @include desktop-md {
    height: rem(170);
    width: rem(150);
    margin-top: 2%;
  }

  @include desktop-l {
    height: rem(305);
    width: rem(258);
    margin-top: 0;
    margin-left: -5%;
  }

  position: relative;
  width: rem(382);
  height: rem(450);
  margin-left: -3%;
}

.promiseGarage .promiseImage {
  @include mobile-s {
    height: rem(100);
    width: rem(85);
  }

  @include mobile-xs {
    height: rem(70);
    width: rem(59);
  }

  @include desktop-md {
    margin-top: 0;
  }

  @include desktop-l-and-bigger {
    height: rem(252);
    width: rem(213);
  }

  margin-left: 0;
}

.promiseTitle {
  @include desktop-md {
    font-size: rem(15);
  }

  @include desktop-l {
    font-size: rem(21);
  }

  @include desktop-xl {
    font-size: rem(26);
  }

  @include mobile {
    line-height: rem(22);
  }

  @include desktop {
    line-height: rem(27);
  }

  color: $brown;
  font-weight: 400;
  text-transform: uppercase;
}

.promiseGarage .promiseTitle {
  @include desktop {
    margin-bottom: rem(3);
    font-size: rem(15);
    line-height: rem(18);
  }
}

.promiseTitle,
.promiseText {
  text-align: left;
}

.promiseText {
  @include mobile {
    font-size: rem(18);
    line-height: rem(25);
  }

  @include desktop-md {
    font-size: rem(21);
    line-height: rem(25);
  }

  @include desktop-l {
    font-size: rem(29);
    line-height: rem(34);
  }

  @include desktop-xl {
    font-size: rem(35);
    line-height: rem(42);
  }

  font-weight: 700;
  text-transform: uppercase;
}

.promiseTextRed {
  color: $red;
}

.promiseTextBrown {
  color: $brown;
}

.promiseGarage .promiseText {
  @include desktop {
    display: inline;
    font-size: rem(20);
    line-height: rem(24);
  }
}

.promiseDetail {
  max-width: rem(607);
  margin-left: 0;
  color: $brown;
  font-weight: 300;
  font-size: rem(16);
  line-height: rem(19);
  text-align: left;
  text-transform: none;
}
