@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.avisSection {
  @include desktop {
    padding: rem(72);
  }

  @include mobile {
    padding-top: rem(72);
  }

  background-color: hsl(0deg 0% 43.9% / 5%);
}

.avisContainer {
  @include mobile {
    padding: 0 !important;
  }

  margin: auto;
}

.title {
  font-size: rem(20);
  font-weight: 700;
  line-height: rem(24);
}

.avisCritizr {
  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  justify-content: center;
  margin-top: rem(29);
  margin-bottom: rem(28);
  color: $brown !important;
}

.avisList {
  margin-top: rem(24);
  color: $brown;
}

.avisItem {
  @include mobile {
    padding: rem(15) rem(40);
  }

  position: relative;
  display: flex;
  flex-direction: column;
  width: rem(250);
  min-height: rem(288);
  padding: rem(15);
  border-radius: rem(4);
  margin-left: rem(13);
  line-height: rem(17);
  background: $light;
}

.avisItemName {
  display: block;
  width: 100%;
  margin-bottom: rem(10);
  color: $red;
  font-weight: 700;
}

.avisItemComment {
  margin-top: rem(10);
  margin-bottom: 0;
  font-weight: 300;
}

.avisItemPublish {
  position: absolute;
  bottom: rem(15);
  left: rem(15);
  display: inline-block;
  margin-top: rem(30);
  font-weight: 100;
}

.avisButton {
  @include mobile {
    padding-bottom: rem(30);
  }

  margin-top: rem(30);
  text-align: center;
}

.avisListWrapper {
  @include desktop-l-and-bigger {
    margin: 0 rem(10);
  }
}

.nextArrow {
  @include desktop {
    right: rem(-20) !important;
  }
}

.prevArrow {
  @include desktop {
    left: rem(-20) !important;
  }
}

.edges {
  opacity: 0.3;
}
